import React from 'react';
import SocialWrapper from 'components/home/SocialWrapper';
import TwitterContainer from 'components/home/TwitterContainer';
import TwitchContainer from 'components/home/TwitchContainer';
import YoutubeContainer from 'components/home/YoutubeContainer';

const Socials = () => {
  return (
    <div className="home_social_row__wrapper">
      <div className="home_social_row">
        <SocialWrapper
          socialType="twitter"
        >
          <TwitterContainer/>
        </SocialWrapper>
        <SocialWrapper
          socialType="twitch"
        >
          <TwitchContainer/>
        </SocialWrapper>
        <SocialWrapper
          socialType="youtube"
        >
          <YoutubeContainer/>
        </SocialWrapper>
      </div>
    </div>
  );
};

export default Socials;