import React from 'react';
import {connect} from 'react-redux';
import YoutubeObject from 'components/home/YoutubeObject';
import {pageData} from 'data/pageData';

import {
  selectYoutubeData,
} from 'reducers';

let json = require('config.json');
const youtubeName = json.youtubeName;

const YoutubeContainer = ({youtubeData}) => {
  return(
    <div className="social__container">
      <div className="twitter_header">
        <a
          href={pageData.youtubeLink}
          aria-label="youtube channel"
          target='_blank'
          rel='noopener noreferrer'
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/></svg>
          &nbsp;{youtubeName}
        </a>
      </div>
      <div>
        {youtubeData && youtubeData.length >= 2 && youtubeData.slice(0,6).map((value, index)=>(
          <div key={index}>
            <YoutubeObject
              youtubeData = {value}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    youtubeData: selectYoutubeData(state),
  };
};

export default connect (mapStateToProps, null)(YoutubeContainer);

