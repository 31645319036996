export const CHAT_REORDER = Symbol('CHAT_REORDER');
export const CHAT_RESIZE = Symbol('CHAT_RESIZE');
export const CHAT_UI_POP_OUT = Symbol('CHAT_UI_POP_OUT');
export const CHAT_UI_POP_IN = Symbol('CHAT_UI_POP_IN');

export const chatReorder = (chatPosition) =>{
  return{
    type: CHAT_REORDER,
    chatPosition,
  };
};
export const chatResize = (chatSize) =>{
  return{
    type: CHAT_RESIZE,
    chatSize,
  };
};
export const popOut = () => (dispatch) => {
  const chatWindow = window.open('/embed/chat', 'chat', 'height=600,width=350');
  chatWindow.addEventListener('beforeunload', () => dispatch(popIn()));
  return dispatch({
    type: CHAT_UI_POP_OUT,
  });
};

export const popIn = () => ({
  type: CHAT_UI_POP_IN,
});