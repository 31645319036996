import { useEffect } from 'react';

const SetSiteIcon = () => {
  useEffect(() => {
    var link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/static/images/logo.png';
    document.getElementsByTagName('head')[0].appendChild(link);
  }, []);
  return null;
};

export default SetSiteIcon;