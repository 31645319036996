import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {
  selectIsMobile
} from 'reducers';

class Collections extends React.Component {
  render() {

    const {
      isMobile,
      srcLoaded,
    } = this.props;

    const imageName = classNames(
      'home_collections__image',
      {
        'home_collections__image--mobile': isMobile,
      }
    );

    return (
      <div className="home_collections__wrapper">
        <a
          href="https://www.twitch.tv/kaitlyn/videos?filter=collections"
          aria-label="playthrough collections"
          target="_blank"
          rel="noopener noreferrer"
          className="home_collections__container"
        >
          <div className="explore_text_block__topper"/>
          <div className="home_collections__image__container">
            <div className={imageName} style={{backgroundImage: `url(${srcLoaded})`}}/>
          </div>
          <div className="home_collections__overlay_title">
            CLICK TO WATCH
          </div>
          <div className="home_collections__body">
            See my collections on twitch<br/>
          </div>
        </a>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isMobile: selectIsMobile(state),
  }),
  dispatch => ({
  }),
)(Collections);
