import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {pageData} from 'data/pageData';

import {
  selectTwitchLiveValues,
  selectLoadedContent,
} from 'reducers';

class ShowTwitchLive extends React.Component {
  render() {
    const {
      liveValues,
      loadedContent,
    } = this.props;

    const displayName = classNames(
      'twitch_show_live',
      {
        'twitch_show_live--stream': loadedContent[pageData.streamLink],
      }
    );

    return (
      <div className={displayName}>
        <a
          href="http://twitch.tv/kaitlyn"
          aria-label="twitch stream"
          target="_blank"
          rel="noopener noreferrer"
          className="twitch_show_live__link"
        >
          {liveValues ?
            <div className="twitch_show_live__object">
              Stream is online
            </div>
            :
            <div className="twitch_show_live__object">
              Stream is offline
            </div>
          }
        </a>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    liveValues: selectTwitchLiveValues(state),
    loadedContent: selectLoadedContent(state),
  }),
  dispatch => ({
  }),
)(ShowTwitchLive);