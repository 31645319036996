import React from 'react';

const createMarkup = (text) => {
  return {__html: text};
};

const FaqRow = ({question, answer}) => {
  return (
    <React.Fragment>
      <div className="faq_question">
        <div dangerouslySetInnerHTML={createMarkup(question)} />
      </div>
      <div className="faq_answer">
        <div dangerouslySetInnerHTML={createMarkup(answer)} />
      </div>
    </React.Fragment>
  );
};

export default FaqRow;