import React from 'react';
import moment from 'moment';


const TwitchObject = ({
  title,
  url,
  views,
  preview,
  created_at,
  length,
  scrollbar,
}) => {

  const timeText = moment(created_at).fromNow();
  const vodPreview = preview ? preview.replace(/%{width}x%{height}/g, '720x405') : '';

  return(
    <div className="twitch_object" onLoad = {()=>scrollbar && scrollbar.update()}>
      <div className="twitch_image__wrapper">
        <a
          href={url}
          aria-label="twitch vod"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={vodPreview}
            alt="title"
            className="twitch_image"
            loading="lazy"/>
        </a>
      </div>
      <div className="twitch_text">
        <a
          href={url}
          aria-label="twitch vod"
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
        <div className="twitch_subtext">
          <div className="twitch_subtext__half">
            {timeText}
            <br/>
            {views}&nbsp;views
          </div>
          <div className="twitch_subtext__half">
          {length}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwitchObject;