import React from 'react';

let json = require('config.json');
const twitchName = json.twitchName;

class ChatEmbed extends React.Component{
  render(){
    return(
      <div className="stream_chat_embed">
        <iframe frameBorder="0"
          scrolling="no"
          id="chat_embed"
          src={`https://www.twitch.tv/embed/${twitchName}/chat?parent=kaitlynlive.com`}
          height="100%"
          width="100%"
          title="chat"
        >
        </iframe>
      </div>
    );
  }
}

export default ChatEmbed;
