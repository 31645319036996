import React from 'react';
import {connect} from 'react-redux';
import FaqRow from 'components/faq/FaqRow';

const FaqView = ({faqData}) => {
  return (
    <div className="faq__wrapper">
      <div className="faq__container">
        {faqData && faqData.map((value, key) => {
          return (
            <div className="faq__row" key={key}>
              <FaqRow
                question={value.value.question}
                answer = {value.value.answer}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    faqData: state.faq.faqData,
  };
};

export default connect (mapStateToProps, null)(FaqView);
