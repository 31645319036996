import React from 'react';
import {Link} from 'react-router-dom';

const TextBlockDisplay = ({title, linkText, linkValue, image, isLeft}) => {
  return (
    <div className="explore_text_block">
      <Link to={linkValue}>
        <div className="explore_text_block__topper"/>
        <div className="explore_text_block__object">
          <div className="explore_image__wrapper">
            <img
              src={image}
              alt="title"
              className="explore_image"
              style={isLeft && {objectPosition: '0 0'}}
              loading="lazy"
            />
          </div>
          <div className="explore_title explore_subtitle">
            {title}
          </div>
          <div className="explore_link">
            {linkText}
          </div>
        </div>
      </Link>
    </div>
  );
};

const Explore = ({isMobile}) => {
  return (
    <div className="explore__wrapper">
      <div className="explore_text">
        <TextBlockDisplay
          title="About"
          subtitle="Subtitle"
          linkText="More About Kaitlyn"
          linkValue="/about"
          image='/static/images/about.jpg'
          isLeft={true}
        />
        <TextBlockDisplay
          title="Equipment"
          subtitle="Subtitle"
          linkText="My Gaming & Streaming Setup"
          linkValue="/equipment"
          image='/static/images/equipment/main-small.jpg'
        />
        <TextBlockDisplay
          title="FAQ"
          subtitle="Subtitle"
          linkText="Frequently Asked Questions"
          linkValue="/faq"
          image='/static/images/banners/banner3-small.jpg'
        />
      </div>

    </div>
  );
};

export default Explore;