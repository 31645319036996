import React from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {pageData} from 'data/pageData';
import scroll from 'react-scroll';
import {
  selectLoadedContent
} from 'reducers';

let scroller = scroll.animateScroll;

const ScrollTopBar = ({loadedContent}) => {
  const wrapperName = classNames(
    'scroll_top_bar__wrapper',
    {
      'scroll_top_bar__wrapper--stream': loadedContent[pageData.streamLink],
    }
  );

  return (
    <div
      className={wrapperName}
    >
      <div className="footer__text">
        <strong>PO Box</strong><br/>
        Kaitlyn Richelle<br/>
        99900 WZ 240 468<br/>
        RPO CARLING<br/>
        London ON N6A 0B5<br/>
        <br/><br/>
        kaitlyn.twitch.business@gmail.com<br/>
      </div>
      <div
        onClick={()=>scroller.scrollToTop()}
      >
        <svg
          className="scroll_top_bar__button"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadedContent: selectLoadedContent(state),
  };
};

export default connect (mapStateToProps, null)(ScrollTopBar);
