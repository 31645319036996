import React from 'react';
import Banner from 'components/Banner';
import Divider from 'components/home/Divider';
import FaqView from 'components/faq/FaqView';

class Faq extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){
    return (
      <div>
        <Banner
          srcPreload='/static/images/banners/banner3-fallback.jpg'
          srcLoaded='/static/images/banners/banner3.jpg'
        >
          <div className='splash_header glitch'>
            <div className='splash_title'>
              Faq
            </div>
          </div>
        </Banner>

        <Divider>
          Frequently Asked Questions
        </Divider>

        <FaqView/>
      </div>
    );
  }
}

export default Faq;