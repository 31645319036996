import React from 'react';
import {Link} from 'react-router-dom';
import * as menuActions from 'actions/menu';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {pageData} from 'data/pageData';
import SvgIcon from 'components/SvgIcon';

import {
  selectMenuDisplay,
} from 'reducers';

class MenuText extends React.Component{
  toggleMenu = () => {
    this.props.menuActions.toggleMenu(false);
  }
  render(){
    const {
      menuDisplay,
    } = this.props;
    const menuClassName = classNames(
      'twelve_menu_panel__links',
      {
        'twelve_menu_panel__links--display': menuDisplay
      }
    );
    const wrapperName = classNames(
      'twelve_menu_panel__links_wrapper',
      {
        'twelve_menu_panel__links_wrapper--display': menuDisplay
      }
    );

    const menuValues = [
      {text: 'Home', link: pageData.homeLink, outLink: false},
      {text: 'About', link: pageData.aboutLink, outLink: false},
      {text: 'Equipment', link: pageData.equipmentLink, outLink: false},
      {text: 'FAQ', link: pageData.faqLink, outLink: false},
      {text: 'Playthroughs', link: 'https://www.twitch.tv/kaitlyn/videos?filter=collections', outLink: true},
      {text: 'Contact', link: pageData.contactLink, outLink: false},
      {text: 'Stream', link: pageData.streamLink, outLink: false},
    ];
    const mobileIcons = [
      {link:pageData.twitchLink, icon: 'twitch'},
      {link:pageData.twitterLink, icon: 'twitter'},
      {link:pageData.youtubeLink, icon: 'youtube'},
      {link:pageData.instagramLink, icon: 'instagram'},
    ];

    return(
      <div className={wrapperName}>
        <div className = {menuClassName}>
          {menuValues.map((value, index)=>(
            value.outLink ?
              <div key={index} className="twelve_menu_panel__sect">
                <a
                  href={value.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className='twelve_menu_panel__link'
                  aria-label={value.text}
                >
                  {value.text}
                </a>
              </div>
              :
              <div key={index} className="twelve_menu_panel__sect">
                <Link
                  to={value.link}
                  className='twelve_menu_panel__link'
                  onClick = {this.toggleMenu}
                >
                  {value.text}
                </Link>
              </div>
          ))}
          <div className="mobile_menu_social_icons twelve_menu_panel__sect">
            {mobileIcons.map((value, index)=>(
              <div className='navbar_option' key={index}>
                <a
                  aria-label={value.icon}
                  href={value.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SvgIcon name={value.icon} className='mobile_menu_social_icon'/>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    menuDisplay: selectMenuDisplay(state),
  }),
  dispatch => ({
    menuActions: bindActionCreators(menuActions, dispatch),
  }),
)(MenuText);
