import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

let youtubeBaseUrl = 'https://www.youtube.com/watch?v=';

const TitleDisplay = ({snippet, videoId}) => {
  if (snippet){
    return (
      <div>
        <a
          href= {`${youtubeBaseUrl}${videoId}`}
          aria-label="youtube video"
          target="_blank"
          rel="noopener noreferrer"
          className="youtube_title"
        >
          {snippet.title}
        </a>
      </div>
    );
  }
  else {
    return null;
  }
};

const ThumbnailDisplay = ({snippet, videoId}) => {
  if (snippet && snippet.thumbnails?.default?.url){
    return (
      <div className="youtube_image__wrapper">
        <a
          href= {`${youtubeBaseUrl}${videoId}`}
          aria-label="youtube video"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            loading="lazy"
            src={snippet.thumbnails?.high?.url}
            alt="youtube thumbnail"
            className="youtube_image"
          />
        </a>
      </div>
    );
  }
  else {
    return null;
  }
};

const StyledText = styled.div`
  padding-top: 1rem;
  box-sizing: border-box;
`;

const YoutubeObject = ({youtubeData}) => {
  let youtubeLink = '';
  let createdAt = '';
  if (youtubeData.snippet) {
    if (youtubeData.snippet.resourceId){
      youtubeLink = youtubeData.snippet.resourceId.videoId;
      createdAt = moment(youtubeData.snippet.publishedAt).fromNow();
    }
  }
  return(
    <div className="youtube_object">
      <ThumbnailDisplay
        snippet = {youtubeData.snippet}
        videoId = {youtubeLink}
      />
      <StyledText>
        <TitleDisplay
          snippet = {youtubeData.snippet}
          videoId = {youtubeLink}
        />
        <div className="twitch_subtext">
          {createdAt}
        </div>
      </StyledText>
    </div>
  );
};

export default YoutubeObject;