import React from 'react';
import Banner from 'components/Banner';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as scrollActions from 'actions/scroll';

import AboutBar from 'components/AboutBar';

import {
  selectBioRows,
} from 'reducers';

const createMarkup = (text) => {
  return {__html: text};
};

class AboutCouch extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectImage = (image) => {
    this.props.scrollActions.selectImage(image);
  }

  render(){
    const {
      bioRows,
    } = this.props;

    return (
      <div>
        <Banner
          srcPreload='/static/images/banners/banner4-fallback.jpg'
          srcLoaded='/static/images/banners/banner4.jpg'
        >
          <div className='splash_header glitch'>
            <div className='splash_title'>
              About
            </div>
          </div>
        </Banner>

        <div className="about__wrapper">
          {bioRows && bioRows.length >= 3 && bioRows.map((value, key) => {
            return (
              <div className="about_object__wrapper" key={key}>
                <AboutBar text={value.text}>
                  <div className="about_object__image__wrapper">
                    <div className="about_object__image">
                      <img
                        src={value.value.url} alt={value.text}
                        onClick={()=>this.selectImage(value.value.url)}
                      />
                    </div>
                  </div>
                  {value.value.text &&
                    <div className="about_object__text">
                      <div dangerouslySetInnerHTML={createMarkup(value.value.text)} />
                    </div>
                  }
                </AboutBar>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    bioRows: selectBioRows(state),
  }),
  dispatch => ({
    scrollActions: bindActionCreators(scrollActions, dispatch),
  }),
)(AboutCouch);