export const FETCH_BIO_ROWS_STARTED = Symbol('FETCH_BIO_ROWS_STARTED');
export const FETCH_BIO_ROWS_SUCCEEDED = Symbol('FETCH_BIO_ROWS_SUCCEEDED');
export const FETCH_BIO_ROWS_FAILURE = Symbol('FETCH_BIO_ROWS_FAILURE');

const fetchBioRowsStarted = request => ({type: FETCH_BIO_ROWS_STARTED, request});
const fetchBioRowsSucceeded = data => ({type: FETCH_BIO_ROWS_SUCCEEDED, data});
const fetchBioRowsFailure = (data, error) => ({type: FETCH_BIO_ROWS_FAILURE, data, error});

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function getBioRows() {
  return () => {
    return fetch('/api/v1/bio');
  };
}
export function fetchBioRows() {
  return (dispatch) => {
    dispatch(fetchBioRowsStarted());
    return dispatch(getBioRows())
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchBioRowsSucceeded(json));
      })
      .catch(error => dispatch(fetchBioRowsFailure(error)));
  };
}