import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import * as streamActions from 'actions/stream';
import {pageData} from 'data/pageData';
import SvgIcon from 'components/SvgIcon';

import {
  selectStreamChatPosition,
  selectStreamChatSize,
  selectLoadedContent,
  selectStreamChatPopped,
} from 'reducers';

const json = require('config.json');
const twitchName = json.twitchName;

class Stream extends React.Component {
  chatReorder = (chatPosition) => {
    this.props.streamActions.chatReorder(!chatPosition);
  }
  chatResize = (chatSize) => {
    this.props.streamActions.chatResize(!chatSize);
  }
  onClickPopOut = () => {
    this.props.streamActions.popOut();
  }
  render(){
    const {
      chatPosition,
      chatSize,
      loadedContent,
      chatPopped,
    } = this.props;

    const wrapperName = classNames(
      'stream_wrapper',
      {
        'stream_wrapper--reverse': chatPosition === false,
        'stream_wrapper--resize': chatSize === false || chatPopped,
        'stream_wrapper--hidden': !loadedContent[pageData.streamLink],
      }
    );

    return (
      <div className={wrapperName}>

        <div className="stream_bigscreen">
          <iframe
            src={`https://player.twitch.tv/?channel=${twitchName}&muted=true&parent=kaitlynlive.com`}
            height="100%"
            width="100%"
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
            title="stream"
          >
          </iframe>
        </div>
        <div className="stream_chat">
          <div
            className="stream_chat__top"
          >
            <div
              className="stream_chat__icon"
              onClick={()=>this.onClickPopOut()}
            >
              <SvgIcon name="external-link"/>
            </div>

            <div
              className="stream_chat__icon"
              onClick={()=>this.chatReorder(chatPosition)}
            >
              {chatPosition ?
                <SvgIcon name="arrow-left"/>
                :
                <SvgIcon name="arrow-right"/>
              }
            </div>

            <div
              className="stream_chat__icon"
              onClick={()=>this.chatResize(chatSize)}
            >
              <SvgIcon name="fullscreen"/>
            </div>

          </div>
          <div className="stream_chat__bottom">
            <iframe frameBorder="0"
              scrolling="no"
              id="chat_embed"
              src={`https://www.twitch.tv/embed/${twitchName}/chat?parent=kaitlynlive.com`}
              height="100%"
              width="100%"
              title="chat"
            >
            </iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    chatPosition: selectStreamChatPosition(state),
    chatSize: selectStreamChatSize(state),
    loadedContent: selectLoadedContent(state),
    chatPopped: selectStreamChatPopped(state),
  }),
  dispatch => ({
    streamActions: bindActionCreators(streamActions, dispatch),
  }),
)(Stream);

