export const ADD_ERROR_STARTED = Symbol('ADD_ERROR_STARTED');
export const ADD_ERROR_SUCCEEDED = Symbol('ADD_ERROR_SUCCEEDED');
export const ADD_ERROR_FAILURE = Symbol('ADD_ERROR_FAILURE');

const addErrorStarted = request => ({type: ADD_ERROR_STARTED, request});
const addErrorSucceeded = data => ({type: ADD_ERROR_SUCCEEDED, data});
const addErrorFailure = (data, error) => ({type: ADD_ERROR_FAILURE, data, error});

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
function postError(data) {
  console.log(JSON.stringify(data));
  return () => {
    return fetch('/api/v1/error',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  };
}
export function addError(values) {
  return (dispatch) => {
    dispatch(addErrorStarted());
    return dispatch(postError(values))
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(addErrorSucceeded(json));
      })
      .catch(error => dispatch(addErrorFailure(error)));
  };
}