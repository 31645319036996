import {useEffect} from 'react';
import {connect} from 'react-redux';
import * as imagesActions from 'actions/images';
import * as faqActions from 'actions/faq';
import * as equipmentActions from 'actions/equipment';

const GetDatabase = ({ fetchBioRows, fetchFaq, fetchEquipment }) => {
  useEffect(() => {
    fetchBioRows();
    fetchFaq();
    fetchEquipment();
  }, [fetchBioRows, fetchFaq, fetchEquipment]);
  return null;
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBioRows :() => {
      dispatch(imagesActions.fetchBioRows());
    },
    fetchFaq: () => {
      dispatch(faqActions.fetchFaq());
    },
    fetchEquipment: () => {
      dispatch(equipmentActions.fetchEquipment());
      dispatch(equipmentActions.fetchDescription());
    }
  };
};

export default connect (null, mapDispatchToProps)(GetDatabase);
