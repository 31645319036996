import {
  CHECK_SCROLL,
  SET_MOBILE,
  SELECT_IMAGE,
  SELECT_SOCIAL_PANEL,
} from 'actions/scroll';

const DEFAULT_STATE={
  scrollAmount:0,
  isMobile: true,
  selectedImage: false,
  socialPanels: {
    twitter: true,
    twitch: true,
    youtube: true,
  },
};

export default(state=DEFAULT_STATE, payload)=>
{
  switch(payload.type){
  case CHECK_SCROLL:
    return state = {
      ...state,
      scrollAmount:payload.scrollAmount
    };
  case SET_MOBILE:
    return state = {
      ...state,
      isMobile: payload.payload
    };
  case SELECT_IMAGE:
    return state = {
      ...state,
      selectedImage: payload.payload
    };
  case SELECT_SOCIAL_PANEL:
    return {
      ...state,
      socialPanels: {
        ...state.socialPanels,
        [payload.socialType]: payload.socialPanel,
      }
    };
  default:
    return state;
  }
};
