import {
  FETCH_EQUIPMENT_SUCCEEDED,
  FETCH_DESCRIPTION_SUCCEEDED
} from 'actions/equipment';

const DEFAULT_STATE={
  equipment: [],
  description: [],
};

export default(state=DEFAULT_STATE, payload)=>
{
  switch(payload.type){
  case FETCH_EQUIPMENT_SUCCEEDED:
    return state = {
      ...state,
      equipment: payload.data
    };
  case FETCH_DESCRIPTION_SUCCEEDED:
    return state = {
      ...state,
      description: payload.data
    };
  default:
    return state;
  }
};
