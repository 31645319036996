import {
  FETCH_FAQ_SUCCEEDED,
} from 'actions/faq';

const DEFAULT_STATE={
  faqData: [],
};

export default(state=DEFAULT_STATE, payload)=>
{
  switch(payload.type){
  case FETCH_FAQ_SUCCEEDED:
    return state = {
      ...state,
      faqData: payload.data
    };
  default:
    return state;
  }
};
