import React from 'react';
import {connect} from 'react-redux';
import LoadIn from 'components/LoadIn';

const EquipmentBody = ({equipment}) => {
  return (
    <div className="equipment__wrapper">
      <div className="equipment__images">
        {equipment && equipment.map((value, key) => {
          return (
            <div className="equipment_object__wrapper" key={key}>
              <LoadIn>
                <a
                  href={value.value.link}
                  aria-label={value.value.label}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="equipment_object__link"
                >
                  <div className="equipment_object__object">
                    <img src={value.value.url} className="equipment_object__image" alt={value.value.title ? value.value.title : 'title card'}/>
                  </div>
                  {value.value.title && value.value.subtitle &&
                    <div className="equipment_object__text">
                      <div className="equipment_object__label">
                        {value.value.title}
                      </div>
                      {value.value.subtitle}
                    </div>
                  }
                </a>
              </LoadIn>
            </div>
          );
        })
        }
      </div>
      <div className="equipment_notice">
        *affiliate links are used
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    equipment: state.equipment.equipment,
  };
};

export default connect (mapStateToProps, null)(EquipmentBody);
