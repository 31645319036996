import menu from 'reducers/menu';
import scroll from 'reducers/scroll';
import twitch from 'reducers/twitch';
import transition from 'reducers/transition';
import twitter from 'reducers/twitter';
import youtube from 'reducers/youtube';
import stream from 'reducers/stream';
import calendar from 'reducers/calendar';
import images from 'reducers/images';
import faq from 'reducers/faq';
import equipment from 'reducers/equipment';
import mouse from 'reducers/mouse';

import {createSelector} from 'reselect';

const reducers={
  menu,
  scroll,
  twitch,
  transition,
  twitter,
  youtube,
  stream,
  calendar,
  images,
  faq,
  equipment,
  mouse,
};

export default reducers;

/*======================================
=                 MENU                =
======================================*/
export const selectMenuDisplay = (state) => state.menu.menuDisplay;

/*======================================
=              TRANSITION              =
======================================*/
export const selectTransitionStatus = (state) => state.transition.transitionStatus;
export const selectLoadedContent = (state) => state.transition.loadedContent;

export const selectTransitionInProgress = createSelector(
  selectTransitionStatus,
  (transitionStatus) => {
    if (transitionStatus === 'start' || transitionStatus === 'end') {
      return true;
    }
    else {
      return false;
    }
  }
);
export const selectTransitionEnding = createSelector(
  selectTransitionStatus,
  (transitionStatus) => {
    if (transitionStatus === 'end') {
      return true;
    }
    else {
      return false;
    }
  }
);
export const selectLoadedContentHome = createSelector(
  selectLoadedContent,
  (loadedContent) => {
    if (loadedContent === '/') {
      return true;
    }
    else {
      return false;
    }
  }
);

export const selectCurrentPage = createSelector(
  selectLoadedContent,
  (loadedContent) => loadedContent ?
    Object.keys(loadedContent).find(key => loadedContent[key] === true)
    :
    ''
);

export const selectPanelSide = (state) => state.transition.panelSide;

export const selectDark = (state) => state.transition.dark;

export const selectTest = (state) => state.transition.test;


/*======================================
=                TWITCH                =
======================================*/
export const selectTwitchVodValues = (state) => state.twitch.twitchVodValues;

export const selectTwitchCurrentVod = (state) => state.twitch.currentVod;

export const selectTwitchLiveValues = (state) => state.twitch.liveValues;

export const selectTwitchFollowerCount = (state) => state.twitch.followerCount;

export const selectTwitchIsLive = createSelector(
  selectTwitchLiveValues,
  (twitchLive) => twitchLive === true ?
    true
    :
    false
);

export const selectTwitchFollowerCountThousands = createSelector(
  selectTwitchFollowerCount,
  (followerCount) => followerCount ?
    `${Math.round((followerCount/1000) *10)/10}k`
    :
    0
);

/*======================================
=               TWITTER                =
======================================*/
export const selectTwitterValues = (state) => state.twitter.twitterValues;

export const selectTwitterImage = (state) => state.twitter.twitterImage;

export const selectTwitterFollowerCount = (state) => state.twitter.followerCount;

export const selectTwitterFollowerCountThousands = createSelector(
  selectTwitterFollowerCount,
  (followerCount) => followerCount ?
    `${Math.round((followerCount/1000) *10)/10}k`
    :
    0
);

export const selectTwitterImageExists = createSelector(
  selectTwitterImage,
  (twitterImage) => twitterImage ?
    twitterImage.length >3
    :
    false
);

/*======================================
=              YOUTUBE               =
======================================*/
export const selectYoutubeData = (state) => state.youtube.youtubeData;

export const selectYoutubeFollowerCount = (state) => state.youtube.followerCount;

export const selectYoutubeFollowerCountThousands = createSelector(
  selectYoutubeFollowerCount,
  (followerCount) => followerCount ?
    `${Math.round((parseInt(followerCount)/1000) *10)/10}k`
    :
    0
);

/*======================================
=                STREAM                =
======================================*/
export const selectStreamChatPosition = (state) => state.stream.chatPosition;
export const selectStreamChatSize = (state) => state.stream.chatSize;
export const selectStreamChatPopped = (state) => state.stream.chatPopped;

/*======================================
=                SCROLL                =
======================================*/
export const selectScrollAmount = (state) => state.scroll.scrollAmount;

export const selectIsMobile = (state) => state.scroll.isMobile;

export const selectSelectedImage = (state) => state.scroll.selectedImage;

export const selectSocialPanels = (state) => state.scroll.socialPanels;

export const selectSelectedImageExists = createSelector(
  selectSelectedImage,
  (selectedImage) => selectedImage ?
    selectedImage.length >3
    :
    false
);

/*======================================
=                CALENDAR                =
======================================*/
export const selectCalendarEvents = (state) => state.calendar.events;
export const selectCalendarEventsTwo = (state) => state.calendar.eventsTwo;

/*======================================
=                DATABASE                =
======================================*/
export const selectBioRows = (state) => state.images.bioRows;

export const selectFaqData = (state) => state.faq.faqData;

