import React, { Component } from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {isNil} from 'ramda';
import {
  selectTransitionStatus,
  selectTransitionInProgress,
} from 'reducers';

class TransitionPanel extends Component {
  render() {

    const {
      transitionInProgress,
      transitionStatus,
    } = this.props;

    const topPanelName = classNames(
      'fourteen_panel__top',
      {
        'fourteen_panel__top--hide': transitionInProgress && !isNil(transitionStatus),
      }
    );

    const backgroundStyle = {
      backgroundImage: 'url(/static/images/five.jpg)',
    };

    return (
      <div
        style={backgroundStyle}
        className={topPanelName}
      />
    );
  }
}

export default connect(
  (state) => ({
    transitionStatus: selectTransitionStatus(state),
    transitionInProgress: selectTransitionInProgress(state),
  }),
  dispatch => ({
  }),
)(TransitionPanel);
