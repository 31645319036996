import React, { Component } from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';

import Lightbox from 'components/Lightbox';
import ShowTwitchLive from 'components/ShowTwitchLive';
import TransitionPanel from 'components/TransitionPanel';
import Menu from 'menu/MenuWrap';
import Navbar from 'components/Navbar';

import SocketFetch from 'components/services/SocketFetch';

import Home from 'pages/Home';
import AboutCouch from 'pages/AboutCouch';
import Equipment from 'pages/Equipment';
import Faq from 'pages/Faq';
import Contact from 'pages/Contact';
import Stream from 'pages/Stream';

import Background from 'components/Background';
import ScrollTopBar from 'components/ScrollTopBar';
import MenuOverlay from 'menu/MenuOverlay';

import {pageData} from 'data/pageData';

import SetSiteIcon from 'components/services/SetSiteIcon';
import DetectMobile from 'components/services/DetectMobile';
import GetDatabase from 'components/services/GetDatabase';

import {
  selectLoadedContent,
  selectMenuDisplay,
} from 'reducers';

class PublicRoutes extends Component {

  render() {
    const {
      loadedContent,
      menuDisplay,
    } =this.props;

    const wrapperName = classNames({
      'page__wrapper': true,
      'page__wrapper--blur': menuDisplay,
    });

    return (
      <div>
        <Background/>
        <div className={wrapperName}>
          {(loadedContent[pageData.homeLink] || loadedContent.length === 0) &&
            <Home key="home"/>}
          {loadedContent[pageData.aboutLink] &&
            <AboutCouch/>}
          {loadedContent[pageData.equipmentLink] &&
            <Equipment/>}
          {loadedContent[pageData.faqLink] &&
            <Faq/>}
          {loadedContent[pageData.contactLink] &&
            <Contact/>}
          {loadedContent[pageData.streamLink] &&
            <Stream/>}
        </div>
        <MenuOverlay/>
        <Navbar/>
        <Menu/>

        <ShowTwitchLive/>
        <TransitionPanel/>

        <SocketFetch/>
        <Lightbox/>

        <ScrollTopBar/>

        <SetSiteIcon/>
        <DetectMobile/>
        <GetDatabase/>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loadedContent: selectLoadedContent(state),
    menuDisplay: selectMenuDisplay(state),
  }),
  dispatch => ({
  }),
)(PublicRoutes);
