import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

class AboutBar extends React.Component{
  state = {
    visible: 0,
  };

  componentDidMount() {
    this.getDistanceFromTop();
    window.addEventListener('scroll', this.getDistanceFromTop);
    window.addEventListener('resize', this.getDistanceFromTop);
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.getDistanceFromTop);
    window.removeEventListener('resize', this.getDistanceFromTop);
  }

  getDistanceFromTop = () => {
    if (!this.state.visible) {
      this.setState({
        visible: (ReactDOM.findDOMNode(this).getBoundingClientRect().top) < window.innerHeight,
      });
    }
  }

  render(){
    const {
      text,
    } = this.props;


    const objectName = classNames(
      'about_object',
      {
        'about_object--filled': text,
        'about_object--display': this.state.visible,
      }
    );

	  return(
      <div className={objectName}>
        {this.props.children}
      </div>
	  );
  }
}

export default AboutBar;
