import React from 'react';
import {Route, Switch} from 'react-router-dom';

import 'styles/main.css';
import 'styles/splash.css';
import 'styles/pages.css';
import 'styles/menu.css';
import 'styles/footer.css';
import 'styles/navbar.css';
import 'styles/transitionpanel.css';
import 'styles/bio.css';
import 'styles/history.css';
import 'styles/contact.css';
import 'styles/stream.css';
import 'styles/faq.css';

import PublicRoutesWrapper from 'routes/PublicRoutesWrapper';
import ChatEmbed from 'components/stream/ChatEmbed';

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/embed/chat" component={ChatEmbed}/>
          <Route path="/" component={PublicRoutesWrapper}/>
        </Switch>
      </div>
    );
  }
}
