import {useEffect} from 'react';
import io from 'socket.io-client';
import {connect} from 'react-redux';
import * as twitchActions from 'actions/twitch';
import * as twitterActions from 'actions/twitter';
import * as youtubeActions from 'actions/youtube';

const SocketFetch = ({ setSocialData }) => {
  useEffect(() => {
    const socket = io();
    socket.on('socialMediaData', (res) => {
      if (res) {
        setSocialData(res);
      }
    });
  });
  return null;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSocialData: (res) => {
      dispatch(twitchActions.setLive(res.isLive));
      dispatch(twitterActions.fetchTwitter(res.twitterData));
      dispatch(twitterActions.fetchTwitterFollowerCount(res.twitterFollowers));
      dispatch(twitchActions.fetchTwitchVods(res.twitchVodData));
      dispatch(twitchActions.fetchTwitchViewCount(res.twitchViews));
      dispatch(twitchActions.fetchTwitchFollowerCount(res.twitchFollowers));
      dispatch(youtubeActions.fetchYoutubeFollowerCount(res.youtubeFollowers));
      dispatch(youtubeActions.receiveYoutubeData(res.youtubeData));
    }
  };
};

export default connect (null, mapDispatchToProps)(SocketFetch);
