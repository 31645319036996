export const FETCH_FAQ_STARTED = Symbol('FETCH_FAQ_STARTED');
export const FETCH_FAQ_SUCCEEDED = Symbol('FETCH_FAQ_SUCCEEDED');
export const FETCH_FAQ_FAILURE = Symbol('FETCH_FAQ_FAILURE');

const fetchFaqStarted = request => ({type: FETCH_FAQ_STARTED, request});
const fetchFaqSucceeded = data => ({type: FETCH_FAQ_SUCCEEDED, data});
const fetchFaqFailure = (data, error) => ({type: FETCH_FAQ_FAILURE, data, error});

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function getFaq() {
  return () => {
    return fetch('/api/v1/faq');
  };
}
export function fetchFaq() {
  return (dispatch) => {
    dispatch(fetchFaqStarted());
    return dispatch(getFaq())
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchFaqSucceeded(json));
      })
      .catch(error => dispatch(fetchFaqFailure(error)));
  };
}