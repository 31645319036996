import React from 'react';
import Banner from 'components/Banner';
import EquipmentBody from 'components/EquipmentBody';
import EquipmentText from 'components/EquipmentText';

class Equipment extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){
    return (
      <div>
        <Banner
          srcPreload='/static/images/banners/equip2-fallback.jpg'
          srcLoaded='/static/images/banners/equip2.jpg'
        >
          <div className='splash_header glitch'>
            <div className='splash_title'>
              Equipment
            </div>
          </div>
        </Banner>
        <EquipmentBody/>
        <EquipmentText/>
      </div>
    );
  }
}

export default Equipment;