import React from 'react';
import Banner from 'components/Banner';
import Divider from 'components/home/Divider';
import Explore from 'components/home/Explore';
import Calendar from 'components/home/Calendar';
import Collections from 'components/home/Collections';
import Socials from 'components/home/Socials';
import Merch from 'components/home/Merch';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {
  selectIsMobile
} from 'reducers';
class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render(){
    const imageName = classNames(
      'explore_banner__inner',
      {
        'explore_banner__inner--mobile': this.props.isMobile,
      }
    );
    return (
      <div>
        <Banner
          srcPreload='/static/images/banners/0-fallback.jpg'
          srcLoaded='/static/images/banners/0.jpg'
          isLeft={true}
        >
          <div className='splash_header glitch'>
            <div className='splash_title'>
              kaitlyn
            </div>
          </div>
        </Banner>

        <Divider>
          explore
        </Divider>
        <Explore/>

        <Divider>
          Merchandise
        </Divider>
        <Merch/>

        <div className="explore_banner">
          <div className={imageName}/>
        </div>

        <Divider>
          Playthrough Videos
        </Divider>
        <Collections
          srcPreload='/static/images/banners/collections-fallback.jpg'
          srcLoaded='/static/images/banners/collections.jpg'
        />

        <Divider>
          schedule
        </Divider>
          <Calendar/>

        <Divider>
          social media
        </Divider>
        <Socials/>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: selectIsMobile(state),
  };
};

export default connect (mapStateToProps, null)(Home);
