import React from 'react';
import {connect} from 'react-redux';
import scroll from 'react-scroll';
import styled from 'styled-components';
import {isNil} from 'ramda';

import {
  selectTransitionStatus,
  selectIsMobile,
  selectTransitionInProgress,
} from 'reducers';

let scroller = scroll.animateScroll;

const scrollDown = () => {
  scroller.scrollTo(window.innerHeight - 60);
};

const StyledBanner = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-position: center top}
  background-size: cover;
  z-index: 2;
  transition: opacity 1s ease;
  background-attachment: ${props => props.isMobile ? 'scroll' : 'fixed'};
  background-image: url(${props => props.srcLoaded});
  @media screen and (max-width: 768px) {
    background-position: ${props => props.isLeft ? '-50px 50px' : 'center top'}
  }

`;
const StyledWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  top: 0px;
  overflow: hidden;
  z-index: 0;
  box-shadow: var(--shadow-standard);
`;

const Banner = ({ transitionStatus, transitionInProgress, isMobile, isLeft, srcLoaded, children }) => {
  return(
    <StyledWrapper
      firstLoad={isNil(transitionStatus)}
      transitionEnded={!transitionInProgress}
    >
      <StyledBanner
        srcLoaded={srcLoaded}
        isLeft={isLeft}
        isMobile={isMobile}
      />
      <div className='splash_header_container'>
        {children}
      </div>
      <div className="splash_divet__wrapper">
      <svg
        onClick={() => scrollDown()}
        className="splash_divet"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
        </svg>

      </div>
    </StyledWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    transitionStatus: selectTransitionStatus(state),
    transitionInProgress: selectTransitionInProgress(state),
    isMobile: selectIsMobile(state),
  };
};

export default connect (mapStateToProps, null)(Banner);
