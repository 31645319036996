import {
  CHAT_REORDER,
  CHAT_RESIZE,
  CHAT_UI_POP_OUT,
  CHAT_UI_POP_IN,
} from 'actions/stream';

const DEFAULT_STATE={
  chatPosition: true,
  chatSize: true,
  chatPopped: false,
};

export default(state=DEFAULT_STATE, payload)=>
{
  switch(payload.type){
  case CHAT_REORDER:
    return state = {
      ...state,
      chatPosition: payload.chatPosition,
    };
  case CHAT_RESIZE:
    return state = {
      ...state,
      chatSize: payload.chatSize,
    };
  case CHAT_UI_POP_OUT:
    return state = {
      ...state,
      chatPopped: true,
    };
  case CHAT_UI_POP_IN:
    return state = {
      ...state,
      chatPopped: false,
    };
  default:
    return state;
  }
};
