import React from 'react';
import Banner from 'components/Banner';

class Contact extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){
    return (
      <div>
        <Banner
          srcPreload='/static/images/banners/splash3-fallback.jpg'
          srcLoaded='/static/images/banners/splash3.jpeg'
        >
          <div className='splash_header glitch'>
            <div className='splash_title'>
              Contact
            </div>
          </div>
        </Banner>

        <div className="contact_wrapper">
          <div className="contact_container">
            <div className="contact_container_half">
              <strong>PO Box</strong><br/>
              Kaitlyn Richelle<br/>
              99900 WZ 240 468<br/>
              RPO CARLING<br/>
              London ON N6A 0B5<br/>
            </div>
            <div className="contact_container_half">
              <strong>Email</strong><br/>
              kaitlyn.twitch.business@gmail.com<br/>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Contact;
