import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {bindActionCreators} from 'redux';
import * as menuActions from 'actions/menu';

import {
  selectMenuDisplay,
} from 'reducers';

class MenuOverlay extends React.Component{

  closeMenu = () => {
    this.props.menuActions.toggleMenu(false);
  }

  render(){
    const {
      menuDisplay,
    } = this.props;

    const wrapperName = classNames(
      'menu_page__overlay',
      {
        'menu_page__overlay--display':menuDisplay
      }
    );

    return(
      <div
        className = {wrapperName}
        onClick={()=>this.closeMenu()}
      />
    );
  }
}

export default connect(
  (state) => ({
    menuDisplay: selectMenuDisplay(state),
  }),
  dispatch => ({
    menuActions: bindActionCreators(menuActions, dispatch),
  }),
)(MenuOverlay);
