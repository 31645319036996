export const FETCH_YOUTUBE_FOLLOWER_COUNT = Symbol('FETCH_YOUTUBE_FOLLOWER_COUNT');
export const RECEIVE_YOUTUBE_DATA = Symbol('RECEIVE_YOUTUBE_DATA');

export const fetchYoutubeFollowerCount = (payload) => {
  return{
    type: FETCH_YOUTUBE_FOLLOWER_COUNT,
    payload: payload,
  };
};

export const receiveYoutubeData = (payload) => {
  return{
    type: RECEIVE_YOUTUBE_DATA,
    payload: payload,
  };
};