import React from 'react';
import classNames from 'classnames';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as scrollActions from 'actions/scroll';

import {
  selectSelectedImage,
  selectSelectedImageExists,
} from 'reducers';

class Lightbox extends React.Component{

  closeImage = () => {
    this.props.scrollActions.selectImage(false);
  }

  render(){
    const {
      selectedImage,
      selectedImageExists,
    } = this.props;

    const wrapperName= classNames(
      'lightbox__wrapper',
      {
        'lightbox__wrapper--display': selectedImageExists,
      }
    );

    return(
      <div className={wrapperName} onClick={()=>this.closeImage()}>
        <img src={selectedImage ? selectedImage : 'false'} alt="twitter full" className="lightbox__image"/>
        <svg className="lightbox__close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    selectedImage: selectSelectedImage(state),
    selectedImageExists: selectSelectedImageExists(state),
  }),
  dispatch => ({
    scrollActions: bindActionCreators(scrollActions, dispatch),
  }),
)(Lightbox);