import React from 'react';
import {connect} from 'react-redux';
import TwitchVodsObject from 'components/home/TwitchObject';

import {
  selectTwitchVodValues,
} from 'reducers';

const json = require('config.json');
const twitchName = json.twitchName;

const TwitchContainer = ({twitchVodValues}) => {
  return(
    <div className="social__container">
      <div className="twitter_header">
        <a
          href={`https://twitch.tv/${twitchName}`}
          aria-label="twitch"
          target='_blank'
          rel='noopener noreferrer'
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M2.149 0l-1.612 4.119v16.836h5.731v3.045h3.224l3.045-3.045h4.657l6.269-6.269v-14.686h-21.314zm19.164 13.612l-3.582 3.582h-5.731l-3.045 3.045v-3.045h-4.836v-15.045h17.194v11.463zm-3.582-7.343v6.262h-2.149v-6.262h2.149zm-5.731 0v6.262h-2.149v-6.262h2.149z" fillRule="evenodd" clipRule="evenodd"/></svg>
          &nbsp;{twitchName}
        </a>
      </div>
      <div className="twitch__container">
        {twitchVodValues && twitchVodValues.length> 6 && twitchVodValues.slice(0,6).map((value, index)=>(
          <div key={index}>
            <TwitchVodsObject
              title = {value.title}
              url = {value.url}
              views = {value.view_count}
              preview = {value.thumbnail_url}
              created_at = {value.created_at}
              length = {value.duration}
              key={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    twitchVodValues: selectTwitchVodValues(state),
  };
};

export default connect (mapStateToProps, null)(TwitchContainer);

