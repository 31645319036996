import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import MenuText from 'menu/MenuText';

import {
  selectMenuDisplay,
} from 'reducers';

class MenuPanel extends React.Component{
  render(){
    const {
      menuDisplay,
    } = this.props;

    const menuClassName = classNames(
      'twelve_menu_panel',
      {
        'twelve_menu_panel--display': menuDisplay
      }
    );

    return(
      <div className = {menuClassName}>
        <MenuText/>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    menuDisplay: selectMenuDisplay(state),
  }),
  dispatch => ({
  }),
)(MenuPanel);
