import React, {useEffect} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {connect} from 'react-redux';
import ErrorBoundary from 'components/services/ErrorBoundary';
import styled from 'styled-components';
import {fetchCalendarAPI} from 'actions/calendar';
import {
  selectCalendarEvents,
} from 'reducers';

const StyledWrapper = styled.div`
  .rbc-date-cell {
    pointer-events: none;
  }
  a {
    pointer-events: none;
  }
`;

// moment used to get prev month and next month to reduce visual lag
const startOfMonth = (date) => {
  const startOfMonth =  new Date(date.getFullYear(), date.getMonth(), 1);
  return moment(startOfMonth).subtract(1, 'months').toDate();
};

const lastDayOfMonth = (date) => {
  const endOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 0);
  return moment(endOfMonth).add(1, 'months').toDate();
};

const CalendarComponent = ({events, fetchCalendar}) => {
  const localizer = momentLocalizer(moment);
  useEffect(() => {
    const dt = new Date();
    fetchCalendar(startOfMonth(dt).toISOString(), lastDayOfMonth(dt).toISOString());
  }, []);
  return events ? (
    <StyledWrapper className="calendar__wrapper">
      <ErrorBoundary component="calendar">
        <Calendar
          toolbar={true}
          localizer = {localizer}
          events = {events}
          onRangeChange={(e) => {
            const dateStart = moment(e.start).subtract(1, 'months').toDate();
            const dateEnd = moment(e.end).add(1, 'months').toDate();
            fetchCalendar(dateStart.toISOString(), dateEnd.toISOString())
          }}
          style={{
            backgroundColor: 'rgba(255, 255, 255, .8)',
          }}
          eventPropGetter={
            (event) => {
              let newStyle = {
                backgroundColor: '#b2f6ea',
              };
              if (event.type === 'one'){
                newStyle.backgroundColor = '#ead1ff';
              }
              return {
                style: newStyle
              };
            }
          }

        />
      </ErrorBoundary>
    </StyledWrapper>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    events: selectCalendarEvents(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCalendar: (start, end) => dispatch(fetchCalendarAPI(start, end))
  };
};

export default connect (mapStateToProps, mapDispatchToProps)(CalendarComponent);
