import {
  FETCH_BIO_ROWS_SUCCEEDED,
} from 'actions/images';

const DEFAULT_STATE={
  bioRows: {},
};

export default(state=DEFAULT_STATE, payload)=>
{
  switch(payload.type){
  case FETCH_BIO_ROWS_SUCCEEDED:
    return state = {
      ...state,
      bioRows: payload.data
    };
  default:
    return state;
  }
};
