import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;
const StyledContainer = styled.div`
  max-width: 80rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  a {
    text-decoration: none;
  }
`;
const StyledImageWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-grow: 1;
`;
const StyledObject = styled.div`
  width: 250px;
  height: 300px;
  padding: 1rem;
  border-top: 2rem solid var(--color-pink);
  background-color: var(--color-black-opaque);
  margin: 0 1rem 1rem 1rem;
  transition: .2s ease;
  color: var(--color-pink);
  font-weight: 700;
  font-size: var(--font-size--medium);
  box-shadow: var(--shadow-standard);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    height: auto;
  }
  img {
    height: 250px;
    width: 250px;
    object-fit: cover;
    object-position: top center;
    transition: .2s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  &:hover {
    border-top: 2rem solid var(--color-purple);
  }
`;
const StyledText = styled.p`
  margin-bottom: 0;
`;

const shirts = [
  {image: '/static/images/merch-0.jpg', alt: `click heads. it's easy. GG.`, link: "https://kait-shop.fourthwall.com/products/kaitlyn-get-griefed-blue-ombre"},
  {image: '/static/images/merch-1.jpg', alt:'Cinnabeanie', link: "https://kait-shop.fourthwall.com/products/cinnahat"},
  {image: '/static/images/merch-2.jpg', alt:'T-shirt', link: "https://kait-shop.fourthwall.com/products/click-heads-og-2020-design"},
  {image: '/static/images/merch-3.jpg', alt:'Fleece Hoodie', link: "https://kait-shop.fourthwall.com/products/just-talented-zip-fleece-hoodie"},
]
const Merch = () => {
  return (
    <StyledWrapper>
      <StyledContainer>
        {shirts?.map(val => {
          return (
            <a
              href={"https://kait-shop.fourthwall.com/" || val.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledObject key={val.alt}>
                <StyledImageWrapper>
                  <img src={val.image} alt={val.alt} />
                </StyledImageWrapper>
                <StyledText>
                  {val.alt}
                </StyledText>
              </StyledObject>
            </a>
          )
        })}
      </StyledContainer>
    </StyledWrapper>
  )
}

export default Merch;