import React from 'react';
import {connect} from 'react-redux';
import * as scrollActions from 'actions/scroll';
import classNames from 'classnames';
import SvgIcon from 'components/SvgIcon';

import {
  selectSocialPanels,
} from 'reducers';

const SocialWrapper = ({selectSocialPanel, children, socialType, socialPanels}) => {
  const wrapperName = classNames(
    'social__wrapper',
    {
      'social__wrapper--display': socialPanels[socialType]
    }
  );
  const overlayName = classNames(
    'social__selector',
    {
      'social__selector--display': socialPanels[socialType]
    }
  );

  return(
    <div className="social__column">
      <div
        className="explore_text_block__topper"
        onClick={()=> selectSocialPanel(socialType, socialPanels[socialType])}
      />
      <div
        className={overlayName}
        onClick={()=> selectSocialPanel(socialType, socialPanels[socialType])}
      >
        <SvgIcon name={socialType}/>
      </div>
      <div className={wrapperName}>
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    socialPanels: selectSocialPanels(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectSocialPanel: (socialType, socialPanel) => {
      dispatch(scrollActions.selectSocialPanel(socialType, !socialPanel));
    }
  };
};

export default connect (mapStateToProps, mapDispatchToProps)(SocialWrapper);
