export const FETCH_CALENDAR = Symbol('FETCH_CALENDAR');
export const FETCH_CALENDAR_TWO = Symbol('FETCH_CALENDAR_TWO');
export const GET_CALENDAR_STARTED = Symbol('GET_CALENDAR_STARTED');
export const GET_CALENDAR_SUCCEEDED = Symbol('GET_CALENDAR_SUCCEEDED');
export const GET_CALENDAR_FAILURE = Symbol('GET_CALENDAR_FAILURE');

const getCalendarStarted = request => ({type: GET_CALENDAR_STARTED, request});
const getCalendarSucceeded = data => ({type: GET_CALENDAR_SUCCEEDED, data});
const getCalendarFailure = (data, error) => ({type: GET_CALENDAR_FAILURE, data, error});

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}


export const fetchCalendar = (payload) => {
  return{
    type: FETCH_CALENDAR,
    payload: payload,
  };
};

export const fetchCalendarTwo = (payload) => {
  return{
    type: FETCH_CALENDAR_TWO,
    payload: payload,
  };
};

const getCalendar = (start, end) => {
  return () => {
    return fetch(`/api/v1/calendar?start=${start}&end=${end}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      });
  };
}
export const fetchCalendarAPI = (start, end) => {
  return (dispatch) => {
    dispatch(getCalendarStarted());
    return dispatch(getCalendar(start, end))
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(getCalendarSucceeded(json));
      })
      .catch(error => dispatch(getCalendarFailure(error)));
  };
}
