import React from 'react';
import {connect} from 'react-redux';
import TwitterObject from 'components/home/TwitterObject';

import {
  selectTwitterValues,
} from 'reducers';

const json = require('config.json');
const twitterName = json.twitterName;

const TwitterContainer = ({twitterValues}) => {
  return(
    <div className="social__container">
      <div className="twitter_header">
        <a
          href={`https://twitter.com/${twitterName}`}
          aria-label="twitter link"
          target='_blank'
          rel='noopener noreferrer'
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
          &nbsp;{twitterName}
        </a>
      </div>
      <div>
        {twitterValues && twitterValues.length >3 && twitterValues.slice(0,6).map((value, index)=>(
          <div key={index}>
            <TwitterObject
              timestamp = {value.created_at}
              text={value.full_text}
              extendedEntities = {value.extended_entities}
              twitterId = {value.id_str}
              key={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    twitterValues: selectTwitterValues(state),
  };
};

export default connect (mapStateToProps, null)(TwitterContainer);

