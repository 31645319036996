import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {pageData} from 'data/pageData';

import {
  selectLoadedContent,
  selectMenuDisplay,
} from 'reducers';

class Background extends React.Component {
  render() {
    const {
      loadedContent,
      menuDisplay,
    } = this.props;

    const backgroundStyle = {
      backgroundImage: 'url(/static/images/five.jpg)',
    };

    const backgroundName = classNames(
      'main_background',
      {
        'main_background--blur': menuDisplay,
        'main_background--hidden': loadedContent[pageData.streamLink],
      }
    );
    return (
      <div
        style={backgroundStyle}
        className={backgroundName}
      >
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loadedContent: selectLoadedContent(state),
    menuDisplay: selectMenuDisplay(state),
  }),
  dispatch => ({
  }),
)(Background);

