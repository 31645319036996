import React from 'react';
import {connect} from 'react-redux';
import * as scrollActions from 'actions/scroll';
import moment from 'moment';

let twitterName = '';
let json = require('config.json');
twitterName = json.twitterName;

const ImageDisplay = ({extendedEntities, selectImage}) => {
  if (extendedEntities && extendedEntities.media[0]){
    return (
      <div className="twitter_object__image_container">
        <img
          src={extendedEntities.media[0].media_url_https}
          loading="lazy"
          alt="twitter extended media"
          className="twitter_object__image"
          onClick={()=>selectImage(extendedEntities.media[0].media_url_https)}
        />
      </div>
    );
  }
  else {
    return null;
  }
};

const createMarkup = (textValue) => { return {__html: textValue}; };

const replaceMentionWithLink = (text) => {
  return text.replace( // replace
    /[@#]\w+/g,     // any mention/hashtag
    function(
      b, // the matched mention/hashtag
      c  // placeholder
    ){
      let a = 'search';       // reuse for 'search'
      c = 'twitter.com/'; // the core twitter url
      return b.link(      // put the match in a link to
        '//' + (          // the '//' protocol, +
          b[a]('#')       // if the match starts with '#'
            ? c             // twitter.com/, or otherwise
            : c + // search.twitter.com/ +
              a + '?q='     // search?q=
        ) +               // and then finally, +
        b.replace(/#/g, '%23')                 // the mention/hashtag.
      )
    }
  );
};

const replaceURLWithHTMLLinks = (text) => {
  let re = /(\(.*?)?\b((?:https?|ftp|file):\/\/[-a-z0-9+&@/#%?=~_()|!:,.;]*[-a-z0-9+&@#%=~_()|])/ig;
  return text.replace(re, function(match, lParens, url) {
    let rParens = '';
    lParens = lParens || '';

    // Try to strip the same number of right parens from url
    // as there are left parens.  Here, lParenCounter must be
    // a RegExp object.  You cannot use a literal
    //     while (/\(/g.exec(lParens)) { ... }
    // because an object is needed to store the lastIndex state.
    let lParenCounter = /\(/g;
    while (lParenCounter.exec(lParens)) {
      let m;
      // We want m[1] to be greedy, unless a period precedes the
      // right parenthesis.  These tests cannot be simplified as
      //     /(.*)(\.?\).*)/.exec(url)
      // because if (.*) is greedy then \.? never gets a chance.
      if (m) {
        if (m === /(.*)(\.\).*)/.exec(url) ||
                /(.*)(\).*)/.exec(url)) {
          url = m[1];
          rParens = m[2] + rParens;
        }
      }
    }
    return `${lParens}<a href=${url}>${url}</a>${rParens}`;
  });
};

const TwitterObject = ({
  selectImage,
  timestamp,
  extendedEntities,
  text,
  twitterId,
}) => {

  const TWITTER_BASE = `https://twitter.com/${twitterName}/status/`;
  const twitterText = replaceMentionWithLink(replaceURLWithHTMLLinks(text));
  const timeText = moment(timestamp, 'ddd MMM DD HH:mm:ss ZZ YYYY').fromNow();

  return(
    <div className="twitter_object">
      <div className="twitter_text">
        <a
          href={`${TWITTER_BASE}${twitterId}`}
          aria-label="twitter profile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg className="twitter_icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
        </a>

        <span dangerouslySetInnerHTML={createMarkup(twitterText)}/>
      </div>

      <ImageDisplay
        extendedEntities = {extendedEntities}
        selectImage = {selectImage}
      />

      {/* REQUIRES THIS IN INDEX.HTML TO OPEN TWITTER ACTIONS IN NEW WINDOW
      <script type="text/javascript" async src="https://platform.twitter.com/widgets.js"></script>*/}

      <div className="twitter_object__bottom">
        <div className="twitter_time">
          {timeText}
        </div>
        <div className="twitter_object__actions">
          <a
            href={`https://twitter.com/intent/tweet?in_reply_to=${twitterId}`}
            aria-label="reply to tweet"
            target="_blank"
            rel="noopener noreferrer"
            className="twitter_object__action"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.026 22.957c10.957-11.421-2.326-20.865-10.384-13.309l2.464 2.352h-9.106v-8.947l2.232 2.229c14.794-13.203 31.51 7.051 14.794 17.675z"/></svg>
          </a>
          <a
            href={`https://twitter.com/intent/retweet?tweet_id=${twitterId}`}
            aria-label="retweet tweet"
            target="_blank"
            rel="noopener noreferrer"
            className="twitter_object__action"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 10v7h10.797l1.594 2h-14.391v-9h-3l4-5 4 5h-3zm14 4v-7h-10.797l-1.594-2h14.391v9h3l-4 5-4-5h3z"/></svg>
          </a>
          <a
            href={`https://twitter.com/intent/favorite?tweet_id=${twitterId}`}
            aria-label="like tweet"
            target="_blank"
            rel="noopener noreferrer"
            className="twitter_object__action"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
          </a>
        </div>
      </div>

    </div>

  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectImage: (image) => {
      dispatch(scrollActions.selectImage(image));
    }
  };
};

export default connect (null, mapDispatchToProps)(TwitterObject);
