export const pageData = {
  homeLink: '/',
  streamLink: '/stream',
  aboutLink: '/about',
  equipmentLink: '/equipment',
  historyLink: '/history',
  contactLink: '/contact',
  faqLink: '/faq',

  twitchLink: 'https://twitch.tv/kaitlyn',
  twitterLink: 'https://twitter.com/kaitly_n',
  youtubeLink: 'https://www.youtube.com/c/kaitlyn_tv',
  instagramLink: 'https://www.instagram.com/kaitlyn_tv/',
};
