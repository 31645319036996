export const CHECK_SCROLL = Symbol('CHECK_SCROLL');
export const SET_MOBILE = Symbol('SET_MOBILE');
export const SELECT_IMAGE = Symbol('SELECT_IMAGE');
export const SELECT_SOCIAL_PANEL = Symbol('SELECT_SOCIAL_PANEL');

export const checkScroll = (scrollAmount) =>{
  return{
    type: CHECK_SCROLL,
    scrollAmount
  };
};
export const setMobile = (payload) =>{
  return{
    type: SET_MOBILE,
    payload
  };
};
export const selectImage = (payload) => {
  return{
    type: SELECT_IMAGE,
    payload: payload,
  };
};
export const selectSocialPanel = (socialType, socialPanel) => {
  return{
    type: SELECT_SOCIAL_PANEL,
    socialType: socialType,
    socialPanel: socialPanel,
  };
};

