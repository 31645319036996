import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import * as transitionActions from 'actions/transition';
import * as scrollActions from 'actions/scroll';
import PublicRoutes from 'routes/PublicRoutes';
import {isNil} from 'ramda';

const PublicRoutesWrapper = ({location, startTransition, loadContent, scrollTop}) => {
  const [current, setCurrent] = useState(null);
  useCallback(() => {
    loadContent(location.pathname, true);
    setCurrent(location.pathname);
  }, [loadContent, location.pathname]);

  useEffect(() => {
    const newLocation = location.pathname;
    if (!isNil(current)) {
      startTransition('start');
    }
    const timer = setTimeout(() => {
      scrollTop();
      if (!isNil(current)) {
        startTransition('end');
      }
      setCurrent(newLocation);
      loadContent(current, false);
      loadContent(newLocation, true);
      const timerTwo = setTimeout(() => {
        startTransition('reset');
      }, 499);
      return () => clearTimeout(timerTwo);
    }, 500);
    return () => clearTimeout(timer);
  }, [location.pathname, loadContent, startTransition, scrollTop]);

  return (
    <PublicRoutes/>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadContent: (page, bool) => {
      dispatch(transitionActions.loadContent(page, bool));
    },
    startTransition: (status) => {
      dispatch(transitionActions.startTransition(status));
    },
    scrollTop: () => {
      dispatch(scrollActions.checkScroll(0));
    },
  };
};

export default connect (null, mapDispatchToProps)(PublicRoutesWrapper);
