import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;
const StyledContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: rgba(231,252,248,.8);
  color: #000;
  padding: 2rem;
  box-shadow: var(--shadow-standard);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: calc(100% - 4rem);
  }
`;
const StyledColumn = styled.div`
  width: 48%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const createMarkup = (text) => {
  return {__html: text};
};

const FaqView = ({description}) => {
  return (
    <StyledWrapper>
      {description && description.map((value, key) => {
        return (
          <StyledContainer key={key}>
            <StyledColumn>
              <div dangerouslySetInnerHTML={createMarkup(value.value.textOne)} />
            </StyledColumn>
            <StyledColumn>
              <div dangerouslySetInnerHTML={createMarkup(value.value.textTwo)} />
            </StyledColumn>
          </StyledContainer>
        );
      })}
    </StyledWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    description: state.equipment.description,
  };
};

export default connect (mapStateToProps, null)(FaqView);
