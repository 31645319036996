import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as errorsActions from 'actions/errors';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.props.errorsActions.addError({
      app: 'kaitlyn',
      component: this.props.component,
      error: error,
      text: info,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default connect(
  (state, ownProps) => ({
  }),
  dispatch => ({
    errorsActions: bindActionCreators(errorsActions, dispatch),
  }),
)(ErrorBoundary);