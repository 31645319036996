import {
  FETCH_CALENDAR,
  FETCH_CALENDAR_TWO,
  GET_CALENDAR_SUCCEEDED,
} from 'actions/calendar';

const DEFAULT_STATE={
  events: [],
  eventsTwo: [],
};

export default(state=DEFAULT_STATE, payload)=>
{
  switch(payload.type){
  case FETCH_CALENDAR:
    return state = {
      ...state,
      events: payload.payload
    };
  case FETCH_CALENDAR_TWO:
    return state = {
      ...state,
      eventsTwo: payload.payload
    };
  case GET_CALENDAR_SUCCEEDED:
    return state = {
      ...state,
      events: payload.data
    };
  default:
    return state;
  }
};
