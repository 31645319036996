export const FETCH_EQUIPMENT_STARTED = Symbol('FETCH_EQUIPMENT_STARTED');
export const FETCH_EQUIPMENT_SUCCEEDED = Symbol('FETCH_EQUIPMENT_SUCCEEDED');
export const FETCH_EQUIPMENT_FAILURE = Symbol('FETCH_EQUIPMENT_FAILURE');

export const FETCH_DESCRIPTION_STARTED = Symbol('FETCH_DESCRIPTION_STARTED');
export const FETCH_DESCRIPTION_SUCCEEDED = Symbol('FETCH_DESCRIPTION_SUCCEEDED');
export const FETCH_DESCRIPTION_FAILURE = Symbol('FETCH_DESCRIPTION_FAILURE');

const fetchEquipmentStarted = request => ({type: FETCH_EQUIPMENT_STARTED, request});
const fetchEquipmentSucceeded = data => ({type: FETCH_EQUIPMENT_SUCCEEDED, data});
const fetchEquipmentFailure = (data, error) => ({type: FETCH_EQUIPMENT_FAILURE, data, error});

const fetchDescriptionStarted = request => ({type: FETCH_DESCRIPTION_STARTED, request});
const fetchDescriptionSucceeded = data => ({type: FETCH_DESCRIPTION_SUCCEEDED, data});
const fetchDescriptionFailure = (data, error) => ({type: FETCH_DESCRIPTION_FAILURE, data, error});

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function getEquipment() {
  return () => {
    return fetch('/api/v1/equipment');
  };
}
export function fetchEquipment() {
  return (dispatch) => {
    dispatch(fetchEquipmentStarted());
    return dispatch(getEquipment())
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchEquipmentSucceeded(json));
      })
      .catch(error => dispatch(fetchEquipmentFailure(error)));
  };
}

function getDescription() {
  return () => {
    return fetch('/api/v1/description');
  };
}
export function fetchDescription() {
  return (dispatch) => {
    dispatch(fetchDescriptionStarted());
    return dispatch(getDescription())
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchDescriptionSucceeded(json));
      })
      .catch(error => dispatch(fetchDescriptionFailure(error)));
  };
}